import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Route,
  Link,
withRouter
} from "react-router-dom";
import $ from "jquery";
import CryptoJS from 'crypto-js';
import crypto from 'crypto-browserify';
import LandingPage from "../containers/landingPage/landingPage";
// import Finxdashboardlanding from "../containers/finxdashboardlanding/finxdashboardlanding";
// import Studentdashboardlanding from "../containers/finxdashboardlanding/studentdashboardlanding";
import Changepassword from "../components/mainpage/forgotpassword/changepassword";
import Forgotpassword from "../components/mainpage/forgotpassword/forgotpassword";
import ForgotpasswordNext from "../components/mainpage/forgotpassword/forgotpasswordmobile";
import CheckoutContainer from "../containers/checkout/checkout-container";
import GdpiScore from "../containers/gdpi/gdpi-score";
import CheckoutPaytm from "./checkout/checkout-paytm";
import Roles from "../containers/roles/roles";
import AdminProfile from "../containers/admin/admin-user";
// import AdminList from "../containers/admin/admin-list";
// import AdminEdit from "../containers/admin/admin-user-edit";
// import OpsList from "../containers/ops/ops-list.js";
import AdminBulkUploadUser from "../components/admin/admin-bulk-upload";
import AdminBulkUploadgdpi from "../components/admin/admin-bulk-upload-gdpi";
import AdminAddUserCourse from "../components/admin/adminAddUserCourse";

import ErrorPage from "./paymentstatus/Error";
import ThankYouPage from "./paymentstatus/ThankYou";
import Finxprivacypolicy from "../containers/finxfooter/finxprivacypolicy";
import Finxdisclaimer from "../containers/finxfooter/finxdisclaimer";
import Finxcookiespolicy from "../containers/finxfooter/finxcookiespolicy";
//import Privacydetails from '../Privacydetails/Privacydetails';
import Privacydetails from "../containers/finxfooter/privacydetails";
import Disclaimer from "../containers/finxfooter/disclaimer";
import Cookies from "../containers/finxfooter/cookies";
import Admindashboardlanding from "../containers/finxdashboardlanding/admindashboardlanding";
import Newadmin from "../components/admin-dashboard/admin";
// import AdminSocialStreaming from "../components/admin-dashboard/adminSocialStreaming";
import NewAdminProfile from "../components/admin-dashboard/newAdminProfile";
import Roadmap from "../components/roadmap";
// import MyAlerts from "../components/student-dashboard/myalerts";
import AdminAlert from "../components/admin-dashboard/adminalerts";
import AdminNotification from "../components/admin-dashboard/adminnotification";
// import Dashboardheader from "../components/dashboardheader/dashboardheader";
import AdminProfileEdit from "./admin-dashboard/adminEditProfile";
import MyTour from "./student-dashboard/mytour";
//import AdminProductModalList from "../components/admin/adminProductModalList";
//import AdminProductModalEdit from "../components/admin/adminProductModalEdit";
// import FinxHeader from "../containers/finxheader/finxheader";
//import AddProduct from "../components/product/addProduct"
// import ProductAddList from "../containers/product-list/productAddContainers";
// import ProductList from "../containers/product-list/productListContainers";
// import ProductEdit from "../containers/product-list/productEditContainers";
import DiscountAdd from "../containers/discount-list/discountAddContainers";
import DiscountList from "../containers/discount-list/discountListContainers";
import DiscountEdit from "../containers/discount-list/discountEditContainers";
import DiscountClone from "../containers/discount-list/discountCloneContainers";

// import VideoAdd from "../containers/video-list/videoAddContainers";
// import VideoList from "../containers/video-list/videoListContainers";
// import VideoEdit from "../containers/video-list/videoEditContainers";

import EventAdd from "../containers/event-list/eventAddContainers";
import EventList from "../containers/event-list/eventListContainers";
import EventEdit from "../containers/event-list/eventEditContainers";

import ProductValidityAdd from "../containers/product-validity-list/productValidityAddContainers";
import ProductValidityEdit from "../containers/product-validity-list/productValidityEditContainers";
import ProductValidityList from "../containers/product-validity-list/productValidityListContainers";

// ops master imports start
// import OpsProductAddList from "../containers/ops-product-list/productAddContainers";
// import OpsProductList from "../containers/ops-product-list/productListContainers";
// import OpsProductEdit from "../containers/ops-product-list/productEditContainers";
import OpsAddProductUser from "../components/ops-product/addProductUsers";

import OpsDiscountAdd from "../containers/ops-discount-list/discountAddContainers";
import OpsDiscountList from "../containers/ops-discount-list/discountListContainers";
import OpsDiscountEdit from "../containers/ops-discount-list/discountEditContainers";
import OpsDiscountClone from "../containers/ops-discount-list/discountCloneContainers";

// import OpsVideoAdd from "../containers/ops-video-list/videoAddContainers";
// import OpsVideoList from "../containers/ops-video-list/videoListContainers";
// import OpsVideoEdit from "../containers/ops-video-list/videoEditContainers";

import OpsEventAdd from "../containers/ops-event-list/eventAddContainers";
import OpsEventList from "../containers/ops-event-list/eventListContainers";
import OpsEventEdit from "../containers/ops-event-list/eventEditContainers";

import OpsProductValidityAdd from "../containers/ops-product-validity-list/productValidityAddContainers";
import OpsProductValidityEdit from "../containers/ops-product-validity-list/productValidityEditContainers";
import OpsProductValidityList from "../containers/ops-product-validity-list/productValidityListContainers";





//ops master imports end

import MyPrequisite from "./student-dashboard/myprequesite";
//import EditProduct from "../components/product/editProduct";
import OrderReportLanding from "../containers/student-report/orderReportLanding";
import EnrollmentLanding from "../containers/report/enrollmentLanding";

import ModulesList from "../containers/modules/modulesListContainers";
import LearningPath from "../containers/learningPath/learningPathContainers";
import Finxfaq from "../containers/finxfooter/finxfaq";
// import Notification from "../components/mainpage/notification";
import AdminHeader from "../components/dashboardheader/adminheader";
import Opsheader from "../components/dashboardheader/opsheader";
import FinxHeader from "../containers/finxheader/finxheader";
import SentEmailList from "../containers/sentemail/sentemail";
import SentEmail from "../components/sentemail/senteEmailList";
import UserActivity from "../components/report/usermanagement";
import UserActivityLanding from "../containers/report/useractivityLanding";
import AssessmentReportLanding from "../containers/report/assessmentLanding";
// import FinixFooterLinks from "../containers/finxfooter/finxfooterlinks";
import FinxFooterLinks from "../containers/finxfooter/finxfooterlinks";
import AdminAddProductUser from "../components/product/addProductUsers";
import UnassignedUsersReportLanding from "../containers/report/unassignedUsersLanding";
import CfxAssessmentReport from "../components/report/cfxAssessmentReport";
import MyCfxAssessmentReport from "../components/student-dashboard/mycfxAssessmentReport";
//const BrowserHistory = require('react-router/lib/BrowserHistory').default;
import EducationalLoanList from "../components/cfx-educationalLoan/cfx-educationLoan";
import EmailVerification from "../components/mainpage/finxregistration/emailVerification";
import RegisterSuccess from "../components/mainpage/finxregistration/registerSuccess";
// import Videos from "./student-dashboard/Videos";
// import Courses from "./student-dashboard/Courses";
// import Jobs from "./student-dashboard/Jobs";
// import MyWishlist from "./student-dashboard/MyWishlist";
// import MyCart from "./student-dashboard/MyCart";

import UploadPayment from "../components/upload-payment/uploadPayment";
import StudentSection from "../components/studentSection/studentSection";
import PathChangeDetectorWithRouter from "../components/popup/PathChangeDetector"
import Opsdashboardlanding from "../containers/finxdashboardlanding/opsdashboardlanding";
// import Candidate from "../components/candidate/candidate";
// import AdminCandidate from "../components/admin-candidate/admin-candidate";
// import CreateJob from "../components/job/createJob";
// import EditJob from "../components/job/editJob";
import BulkJob from "../components/bulk-job/bulkJob";
// import AdminCreateJob from "../components/admin-job/adminCreateJob";
// import AdminEditJob from "../components/admin-job/adminEditJob";
import AdminBulkJob from "../components/admin-job/adminBulkJob";
import JobDetails from "../components/job/jobDetails";
import MorningStar from "../containers/morningStar/morningStar";
import Kotak from "../containers/kotak/kotak";
import loadable from '@loadable/component';
import LoadingFallback from "../components/loadingFallBack";
import {
  getIsMaintenance,
  getTimeRemaining,
  getIsNotified,
  getTimeNotiRemaining,
  getIsClicked,
  getPending,
  getError
} from "../reducer/maintenanceReducer";
import ForcedPopup from '../components/popup/ForcedPopup';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  fetchDeploymentFlagAPI
} from "../services/fetchStudentDashboard.service";
import { LoginService } from "../services/login.service";
import { PopUpPortal } from "../containers/popups/PopUpPortal";
const AdminJob = loadable(() => import("../components/admin-job/adminJob"), { fallback: <LoadingFallback /> });
const Job = loadable(() => import("../components/job/job"), { fallback: <LoadingFallback /> });
const CorporateLandingPage = loadable(() => import("../containers/CorporateLandingPage/CorporateLandingPage"), { fallback: <LoadingFallback /> });
const Videos = loadable(() => import("./student-dashboard/Videos"), { fallback: <LoadingFallback /> });
const Courses = loadable(() => import("./student-dashboard/Courses"), { fallback: <LoadingFallback /> });
const Dashboardheader = loadable(() => import("../components/dashboardheader/dashboardheader"), {
  fallback: <LoadingFallback/>
});
const DataDumpReport = loadable(() => import("../components/report/dataDumpReport"), {
  fallback: <LoadingFallback/>
});
const Finxdashboardlanding = loadable(() => import("../containers/finxdashboardlanding/finxdashboardlanding"), {
  fallback: <LoadingFallback/>
});
const Studentdashboardlanding = loadable(() => import("../containers/finxdashboardlanding/studentdashboardlanding"), {
  fallback: <LoadingFallback/>
});
const Jobs = loadable(() => import("./student-dashboard/Jobs"), {
  fallback: <LoadingFallback/>
});
const MyWishlist = loadable(() => import("./student-dashboard/MyWishlist"), {
  fallback: <LoadingFallback/>
});
const MyCart = loadable(() => import("./student-dashboard/MyCart"), {
  fallback: <LoadingFallback/>
});
const MyAlerts = loadable(() => import("../components/student-dashboard/myalerts"),
{
  fallback: <LoadingFallback/>
});
const Notification = loadable(() => import("../components/mainpage/notification"),
{
  fallback: <LoadingFallback/>
});
const OpsList = loadable(() => import("../containers/ops/ops-list.js"),
{fallback: <LoadingFallback/> });
const AdminEdit = loadable(() => import("../containers/admin/admin-user-edit"),
{fallback: <LoadingFallback/> });
const CreateJob = loadable(() => import("../components/job/createJob"),
{fallback: <LoadingFallback/> });
const EditJob = loadable(() => import("../components/job/editJob"),
{fallback: <LoadingFallback/> });
const OpsVideoAdd = loadable(() => import("../containers/ops-video-list/videoAddContainers"),
{fallback: <LoadingFallback/> });
const OpsVideoList = loadable(() => import("../containers/ops-video-list/videoListContainers"),
{fallback: <LoadingFallback/> });
const OpsVideoEdit = loadable(() => import("../containers/ops-video-list/videoEditContainers"),
{fallback: <LoadingFallback/> });
const OpsProductAddList = loadable(() => import("../containers/ops-product-list/productAddContainers"),
{fallback: <LoadingFallback/> });
const OpsProductList = loadable(() => import("../containers/ops-product-list/productListContainers"),
{fallback: <LoadingFallback/> });
const OpsProductEdit = loadable(() => import("../containers/ops-product-list/productEditContainers"),
{fallback: <LoadingFallback/> });
const AdminCreateJob = loadable(() => import("../components/admin-job/adminCreateJob"),
{fallback: <LoadingFallback/> });
const AdminEditJob = loadable(() => import("../components/admin-job/adminEditJob"),
{fallback: <LoadingFallback/> });
const AdminList = loadable(() => import("../containers/admin/admin-list"),
{fallback: <LoadingFallback/> });
const VideoAdd = loadable(() => import("../containers/video-list/videoAddContainers"),
{fallback: <LoadingFallback/> });
const VideoList = loadable(() => import("../containers/video-list/videoListContainers"),
{fallback: <LoadingFallback/> });
const VideoEdit = loadable(() => import("../containers/video-list/videoEditContainers"),
{fallback: <LoadingFallback/> });
const ProductAddList = loadable(() => import("../containers/product-list/productAddContainers"),
{fallback: <LoadingFallback/> });
const ProductList = loadable(() => import("../containers/product-list/productListContainers"),
{fallback: <LoadingFallback/> });
const ProductEdit = loadable(() => import("../containers/product-list/productEditContainers"),
{fallback: <LoadingFallback/> });
const DownloadDocuments = loadable(
  () => import("../components/download-documents/downloadDocuments"),
  {
    fallback: <LoadingFallback />,
  }
);
const CorporateListLandingPage = loadable(
  () => import("../containers/corporate-list/corporateListContainers"),
  {
    fallback: <LoadingFallback />,
  }
);
const CorporateEdLandingPage = loadable(
  () => import("../containers/CorporateEdLandingPage/CorporateLandingPage"),
  { fallback: <LoadingFallback /> }
);
const AddCorporatePage = loadable(
  () => import("../containers/corporate-list/corporateAddContainers"),
  {
    fallback: <LoadingFallback />,
  }
);
const EditCorporatePage = loadable(
  () => import("../containers/corporate-list/corporateEditContainers"),
  {
    fallback: <LoadingFallback />,
  }
);
const CandidateDetails = loadable(() => import("../components/candidate/candidateDetails"), {
  fallback: <LoadingFallback />,
});
const JobListMaster = loadable(() => import("../components/jobMasterModule/jobListMaster"), {
  fallback: <LoadingFallback />,
});
const AddJobMaster = loadable(() => import("../components/jobMasterModule/addJobMaster"), {
  fallback: <LoadingFallback />,
});
const EditJobMaster = loadable(() => import("../components/jobMasterModule/editJobMaster"), {
  fallback: <LoadingFallback />,
});

const mapStateToProps = state => ({
  isMaintenance: getIsMaintenance(state.maintenanceReducer),
  timeRemaining: getTimeRemaining(state.maintenanceReducer),
  isClicked: getIsClicked(state.maintenanceReducer),
  isNotified:getIsNotified(state.maintenanceReducer),
  timeNotiRemaining:getTimeNotiRemaining(state.maintenanceReducer),
  pending: getPending(state.maintenanceReducer),
  error: getError(state.maintenanceReducer)
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchDeploymentFlagAPI1:fetchDeploymentFlagAPI,
    },
    dispatch
  );

const connectFunction = connect(mapStateToProps, mapDispatchToProps);
const routeFunction=connectFunction(
 class Routes extends Component {
  constructor(props) {
    super(props);
    this.state={
      isMaintenance:this.props.isMaintenance,
      timeRemaining:this.props.timeRemaining,
      timeNotiRemaining:this.props.timeNotiRemaining,
      isNotified:this.props.isNotified,
      isClicked:this.props.isClicked,
      isRouterendered:false,
      currentPath: props.location.pathname,
    }
  }

  fetchDeploymentFlag = () => {
    const {fetchDeploymentFlagAPI1,isMaintenance,isClicked,timeRemaining,isNotified,timeNotiRemaining}=this.props
    LoginService.TokenUser(res => {
      localStorage.setItem('jwt-token', res.responseObject);
      localStorage.setItem('jti-token', res.responseObject);
    fetchDeploymentFlagAPI1((res)=>{
     let isRouterendered1=this.state.isRouterendered
     this.setState({
      isRouterendered:!(isRouterendered1)
    })
    setTimeout(()=>{
      this.setState({
   isRouterendered:true
      })
    },1200)
   
          })     
        })
      };
  componentDidMount() {
    console.log('redux componentDidMount:', this.props); 
    this.fetchDeploymentFlag();
    const url = window?.location?.href;
    const indexOfQuestionMark = url?.indexOf('?');
    let encryptedValue,isEncrypted,isEncrypted1,s11,s2;
    let s3 = {}
    let obj = {};
    
    if (indexOfQuestionMark !== -1) {
      encryptedValue = url?.substring(indexOfQuestionMark + 1);
      // isEncrypted = encryptedValue && encryptedValue.endsWith('=');
      // isEncrypted1 = encryptedValue && encryptedValue.length % 16 === 0;
      // if(isEncrypted || isEncrypted1){
      //   s11 = this.decryptPassword(encryptedValue)
      //   s2 = s11 && s11.split("&")
      //   localStorage.setItem("leadSourceUrl",`/?${encryptedValue}`);
      // }
      if(encryptedValue){
        let paramsCheck = encryptedValue ? encryptedValue.slice(0,3) : "";
        const isDataEncrypted = this.isEncrypted(encryptedValue);
        //! Old Working for normal url with params & encrypted url 
        if(paramsCheck === "cli"){
          s2 = encryptedValue.split("&");
          localStorage.setItem("leadSourceUrl",`/?${encryptedValue}`);
          localStorage.setItem("decryptedSourceUrl", "");
          localStorage.setItem("encryptedUrl","false");
        } else if(isDataEncrypted){
          s11 = this.decryptData(encryptedValue)
          s2 = s11 && s11.split("&")
          localStorage.setItem("leadSourceUrl",`/?${encryptedValue}`);
          localStorage.setItem("decryptedSourceUrl", `/?${s11}`);
          localStorage.setItem("encryptedUrl","true");
        }
        //! New Optmised Code for normal url with params & encrypted url 
        // if (paramsCheck === "cli" || isDataEncrypted) {
        //   const decryptedValue = isDataEncrypted ? this.decryptData(encryptedValue) : encryptedValue;
        //   s2 = decryptedValue.split("&");
      
        //   localStorage.setItem("leadSourceUrl", `/?${encryptedValue}`);
        //   localStorage.setItem("encryptedUrl", isDataEncrypted ? "true" : "false");
        //   localStorage.setItem("decryptedSourceUrl", decryptedValue ? `/?${decryptedValue}` : null);
        // }
      }
      //! Old Alternate to set obj using array index
      for(let i=0;i<s2?.length;i++){      
        s3 =s2[i].split("=")
        obj[s3[0]] = s3[1] ? s3[1] : null
      }    
      //! New Alternate to set obj using key value pair
      // for (let i = 0; i < s2?.length; i++) {
      //   const [key, value] = s2[i].split("=");
      //   obj[key] = value || null;
      // }
      //! New Alternate to set obj using forEach
      // s2 && s2.forEach(entry => {
      //   const [key, value] = entry.split("=");
      //   obj[key] = value || null;
      // });
      console.log('window obj',obj);
      localStorage.setItem('leadObj',JSON.stringify(obj))
      if (localStorage.getItem('userid-Token') === null && localStorage.getItem('login') === null) {
          // window.location.href = '/';
      } else {
        if([5, '5'].includes(localStorage.getItem('roleId'))){
          window.location.href = "/studentDashboard";
        }
      }
    }
  }
  isEncrypted = (data) => {
    // Check if the data is base64 encoded
    return /^[a-zA-Z0-9+/]+[=]{0,2}$/.test(data);
  };
  decryptData = (encryptedString) => {
    const decryptionKey = 'YourSecretKey';
    const decipher = crypto.createDecipher('aes-256-cbc', decryptionKey);
    let decryptedString = decipher.update(encryptedString, 'hex', 'utf-8');
    decryptedString += decipher.final('utf-8');
    return decryptedString;
  };
  decryptPassword = (encryptedPassword) => {
    const decrypted = CryptoJS.AES.decrypt(encryptedPassword, 'secretKey').toString(CryptoJS.enc.Utf8);
    return decrypted;
  };
  render() {
    const{isMaintenance,timeRemaining,isClicked,isNotified,timeNotiRemaining}=this.props
    const { location } = this.props;
    const{isRouterendered}=this.state;
    if([0,"0"].includes(isMaintenance)){
      window.$('#maintainance-modal').modal('hide');
      $('.modal-backdrop').hide();
    }
    return ( 
      <>
      {isRouterendered&&[1,"1"].includes(isMaintenance)&&(!["",null,undefined].includes(timeRemaining))&&
      <ForcedPopup isRouterendered={this.state.isRouterendered} isMaintenance={isMaintenance} timeRemaining={timeRemaining} fetchDeploymentFlag={this.fetchDeploymentFlag} />
     }
      <Router>
        <div>
     <PathChangeDetectorWithRouter fetchDeploymentFlag={this.fetchDeploymentFlag}/>
          <Link to="/"></Link>
          <Link to="/login"></Link>
          <Link to="/register"></Link>
          <Link to="/studentDashboard"></Link>
          <Link to="/userprofile"></Link>
          <Link to="/changepassword"></Link>
          <Link to="/forgotpassword"></Link>
          <Link to="/forgotpasswordnext"></Link>
          <Link to="/checkout"></Link>
          <Link to="/checkout-paytm"></Link>
          <Link to="/admin-list"></Link>
          <Link to="/admin-dashboard"></Link>
          <Link to="/ops-dashboard"></Link>
          <Link to="/roles"></Link>
          <Link to="/gdpi"></Link>
          <Link to="/error"></Link>
          <Link to="/thankyou"></Link>
          <Link to="/finx-privacy-policy"></Link>
          <Link to="/finxdisclaimer"></Link>
          <Link to="/finx-cookies-policy"></Link>
          <Link to="/privacy-details"></Link>
          <Link to="/disclaimer"></Link>
          <Link to="/cookies"></Link>
          <Link to="/new-admin"></Link>
          <Link to="/admin-profile"></Link>
          <Link to="/AdminProfile"></Link>
          <Link to="/admin-edit-user-profile"></Link>
          <Link to="/course-list"></Link>
          <Link to="/add-course"></Link>
          <Link to="/roadmap"></Link>
          <link to="/dashboardheader"></link>
          {<link to="/edit-course"></link>}
          <link to="/adminProductModalList"></link>
          {/* <Link to="/adminProductModalEdit"></Link> */}
          <link to="/welcome-page"></link>
          <link to="/my-tour"></link>
          <link to="/myalerts"></link>
          <link to="/modules-list"></link>
          <Link to="/learning-path"></Link>
          <link to="/adminalert"></link>
          <link to="/adminnotification"></link>
          <link to="/report-enrollment"></link>
          <Link to="/my-Subcription-list"></Link>
          <Link to="/faq"></Link>
          <link to="/notification"></link>
          <link to="/adminheader"></link>
          <link to="/opsheader"></link>
          <link to="/finxheader"></link>
          <Link to="/send-mail"></Link>
          <link to="/send-mail-list"></link>
          <link to="/add-student-course"></link>
          <link to="/add-course-student"></link>
          <link to="/user-activity-report"></link>
          <link to="/user-activity"></link>
          <link to="/assessment-report"></link>
          <Link to="/finixfooter-links"></Link>
          <Link to="/educationalLoanList"></Link>
          <Link to="/EmailVerification"></Link>
          <Link to="/RegisterSuccess"></Link>
          <Link to="/uploadPayment"></Link>
          <Link to="/studentSection"></Link>
          {/* <Link to="/candidate"></Link> */}
          {/* <Link to="/admin-candidate"></Link> */}
          <Link to="/job"></Link>
          <Link to="/createJob"></Link>
          <Link to="/editJob"></Link>
          {/* <Link to="/bulkJob"></Link> */}
          <Link to="/adminJob"></Link>
          <Link to="/adminCreateJob"></Link>
          <Link to="/adminEditJob"></Link>
          {/* <Link to="/adminBulkJob"></Link> */}
          <Link to="/jobDetails"></Link>
          <Link to="/morningstarindia-jobs-research"></Link>
          <Link to="/kotakbank-career-jobs"></Link>
          <Link to="/BFSI-Hiring"></Link>
          <Link to="/higher-education-courses"></Link>

          <Route
            exact
            path="/"
            render={(props) => {
              return <LandingPage {...props} />;
            }}
          ></Route>
          <Route
            exact
            path="/login"
            render={(props) => {
              return <LandingPage {...props} throughLogin={true} />;
            }}
          ></Route>
          <Route
            exact
            path="/register"
            render={(props) => {
              return <LandingPage {...props} throughRegister={true} />;
            }}
          ></Route>
          <Route
            exact
            path="/CfxAssessmentReport/:id/:prodid"
            render={(props) => {
              return <CfxAssessmentReport {...props} />;
            }}
          ></Route>
          <Route
            exact
            path="/MyCfxAssessmentReport/:id/:prodid"
            render={(props) => {
              return <MyCfxAssessmentReport {...props} />;
            }}
          ></Route>
          <Route
            exact
            path="/studentDashboard"
            render={(props) => {
              return <Studentdashboardlanding {...props} />;
            }}
          ></Route>
          <Route
            exact
            path="/videos"
            render={(props) => {
              return <Videos {...props} />;
            }}
          ></Route>
          <Route
            exact
            path="/courses"
            render={(props) => {
              return <Courses {...props} />;
            }}
          ></Route>
          <Route
            exact
            path="/jobs"
            render={(props) => {
              return <Jobs {...props} />;
            }}
          ></Route>
          <Route
            exact
            path="/mywishlist"
            render={(props) => {
              return <MyWishlist {...props} />;
            }}
          ></Route>
          <Route
            exact
            path="/mycart"
            render={(props) => {
              return <MyCart {...props} />;
            }}
          ></Route>
          <Route
            path="/userprofile"
            render={(props) => {
              return <Finxdashboardlanding {...props} />;
            }}
          ></Route>
          <Route
            exact
            path="/changepassword"
            render={(props) => {
              return <Changepassword {...props} />;
            }}
          ></Route>
          <Route
            exact
            path="/forgotpassword"
            render={(props) => {
              return <Forgotpassword {...props} />;
            }}
          ></Route>
          <Route
            exact
            path="/emailVerification/:eid"
            render={(props) => {
              return <EmailVerification {...props} />;
            }}
          ></Route>
          <Route
            exact
            path="/registerSuccess"
            render={(props) => {
              return <RegisterSuccess {...props} />;
            }}
          ></Route>
          <Route
            exact
            path="/forgotpasswordnext"
            render={(props) => {
              return <ForgotpasswordNext {...props} />;
            }}
          ></Route>
          <Route
            path="/checkout"
            render={(props) => {
              return <CheckoutContainer {...props} />;
            }}
          ></Route>
          <Route
            path="/checkout-paytm"
            render={(props) => {
              return <CheckoutPaytm {...props} />;
            }}
          ></Route>
             <Route
              path="/corporate-list"
              render={(props) => {
                return <CorporateListLandingPage {...props} />
              }}
            ></Route>
            <Route
              path="/add-corporate"
              render={(props) => {
                return <AddCorporatePage {...props} />
              }}
            ></Route>
            <Route
              path="/edit-corporate"
              render={(props) => {
                return  <EditCorporatePage {...props} />
              }}
            ></Route>
          <Route
            path="/admin-user-list"
            render={(props) => {
              return <AdminList {...props} />;
            }}
          ></Route>
            <Route
            path="/ops-user-list"
            render={(props) => {
              return <OpsList {...props} />;
            }}
          ></Route>

          <Route
            path="/add-student-course"
            render={(props) => {
              return <AdminAddUserCourse {...props} />;
            }}
          ></Route>

          <Route
            path="/add-course-student"
            render={(props) => {
              return <AdminAddProductUser {...props} />;
            }}
          ></Route>

          <Route
            path="/roles"
            render={(props) => {
              return <Roles {...props} />;
            }}
          ></Route>

          <Route
            path="/gdpi"
            render={(props) => {
              return <GdpiScore {...props} />;
            }}
          ></Route>
          <Route
            path="/userbulkupload"
            render={(props) => {
              return <AdminBulkUploadUser {...props} />;
            }}
          ></Route>
          <Route
            path="/adminbulkuploadgdpi"
            render={(props) => {
              return <AdminBulkUploadgdpi {...props} />;
            }}
          ></Route>
          <Route
            path="/error"
            render={(props) => {
              return <ErrorPage {...props} />;
            }}
          ></Route>
          <Route
            path="/thankyou"
            render={(props) => {
              return <ThankYouPage {...props} />;
            }}
          ></Route>
          <Route
            path="/finx-privacy-policy"
            render={(props) => {
              return <Finxprivacypolicy {...props} />;
            }}
          ></Route>
          <Route
            path="/finx-disclaimer"
            render={(props) => {
              return <Finxdisclaimer {...props} />;
            }}
          ></Route>
          <Route
            path="/finx-cookies-policy"
            render={(props) => {
              return <Finxcookiespolicy {...props} />;
            }}
          ></Route>
          <Route
            path="/privacy-details"
            render={(props) => {
              return <Privacydetails {...props} />;
            }}
          ></Route>
          <Route
            path="/disclaimer"
            render={(props) => {
              return <Disclaimer {...props} />;
            }}
          ></Route>
          <Route
            path="/cookies"
            render={(props) => {
              return <Cookies {...props} />;
            }}
          ></Route>
          <Route
            path="/admin-dashboard"
            render={(props) => {
              return <Admindashboardlanding {...props} />;
            }}
          ></Route>
          <Route
            path="/new-admin"
            render={(props) => {
              return <Newadmin {...props} />;
            }}
          ></Route>
          <Route
            path="/admin-profile"
            render={(props) => {
              return <NewAdminProfile {...props} />;
            }}
          ></Route>
          <Route
            path="/admin-user-profile"
            render={(props) => {
              return <AdminProfile {...props} />;
            }}
          ></Route>
          <Route
            path="/admin-edit-user-profile"
            render={(props) => {
              return <AdminEdit {...props} />;
            }}
          ></Route>
          <Route
            path="/course-list"
            render={(props) => {
              return <ProductList {...props} />;
            }}
          ></Route>

          <Route
            path="/add-course"
            render={(props) => {
              return <ProductAddList {...props} />;
            }}
          ></Route>

          <Route
            path="/edit-course"
            render={(props) => {
              return <ProductEdit {...props} />;
            }}
          ></Route>
          <Route
            path="/discount-list"
            render={(props) => {
              return <DiscountList {...props} />;
            }}
          ></Route>

          <Route
            path="/add-discount"
            render={(props) => {
              return <DiscountAdd {...props} />;
            }}
          ></Route>

          <Route
            path="/edit-discount"
            render={(props) => {
              return <DiscountEdit {...props} />;
            }}
          ></Route>

          <Route
            path="/product-validity-list"
            render={(props) => {
              return <ProductValidityList {...props} />;
            }}
          ></Route>

          <Route
            path="/add-product-validity"
            render={(props) => {
              return <ProductValidityAdd {...props} />;
            }}
          ></Route>

          <Route
            path="/edit-product-validity"
            render={(props) => {
              return <ProductValidityEdit {...props} />;
            }}
          ></Route>
          <Route
            path="/clone-discount"
            render={(props) => {
              return <DiscountClone {...props} />;
            }}
          ></Route>
          <Route
            path="/event-list"
            render={(props) => {
              return <EventList {...props} />;
            }}
          ></Route>

          <Route
            path="/add-event"
            render={(props) => {
              return <EventAdd {...props} />;
            }}
          ></Route>

          <Route
            path="/edit-event"
            render={(props) => {
              return <EventEdit {...props} />;
            }}
          ></Route>

          <Route
            path="/video-list"
            render={(props) => {
              return <VideoList {...props} />;
            }}
          ></Route>

          <Route
            path="/add-video"
            render={(props) => {
              return <VideoAdd {...props} />;
            }}
          ></Route>

          <Route
            path="/edit-video"
            render={(props) => {
              return <VideoEdit {...props} />;
            }}
          ></Route>

          <Route
            path="/admin-profile-edit"
            render={(props) => {
              return <AdminProfileEdit {...props} />;
            }}
          ></Route>
          <Route
            path="/roadmap"
            render={(props) => {
              return <Roadmap {...props} />;
            }}
          ></Route>

          <Route
            path="/myalerts"
            render={(props) => {
              return <MyAlerts {...props} />;
            }}
          ></Route>
          <Route
            exact
            path="/my-tour"
            render={(props) => {
              return <MyTour {...props} />;
            }}
          ></Route>

          <Route
            path="/dashboardheader"
            render={(props) => {
              return <Dashboardheader {...props} />;
            }}
          ></Route>

          <Route
            path="/my-Subcription-list"
            render={(props) => {
              return <MyPrequisite {...props} />;
            }}
          ></Route>

          <Route
            path="/modules-list"
            render={(props) => {
              return <ModulesList {...props} />;
            }}
          ></Route>

          <Route
            path="/learning-path"
            render={(props) => {
              return <LearningPath {...props} />;
            }}
          ></Route>

          <Route
            path="/report-enrollment"
            render={(props) => {
              return <EnrollmentLanding {...props} />;
            }}
          ></Route>
          <Route
            path="/my-orders"
            render={(props) => {
              return <OrderReportLanding {...props} />;
            }}
          ></Route>
          <Route
            path="/adminalert"
            render={(props) => {
              return <AdminAlert {...props} />;
            }}
          ></Route>

          <Route
            path="/adminnotification"
            render={(props) => {
              return <AdminNotification {...props} />;
            }}
          ></Route>

          <Route
            path="/notification"
            render={(props) => {
              return <Notification {...props} />;
            }}
          ></Route>

          <Route
            path="/adminheader"
            render={(props) => {
              return <AdminHeader {...props} />;
            }}
          ></Route>
          <Route
            path="/faq"
            render={(props) => {
              return <Finxfaq {...props} />;
            }}
          ></Route>
          <Route
            path="/finxheader"
            render={(props) => {
              return <FinxHeader {...props} />;
            }}
          ></Route>
          <Route
            path="/send-mail"
            render={(props) => {
              return <SentEmailList {...props} />;
            }}
          ></Route>
          <Route
            path="/send-mail-list"
            render={(props) => {
              return <SentEmail {...props} />;
            }}
          ></Route>
          <Route
            path="/user-activity-report"
            render={(props) => {
              return <UserActivity {...props} />;
            }}
          ></Route>
          <Route
            path="/user-activity"
            render={(props) => {
              return <UserActivityLanding {...props} />;
            }}
          ></Route>
          <Route
            path="/assessment-report"
            render={(props) => {
              return <AssessmentReportLanding {...props} />;
            }}
          ></Route>
          <Route
            path="/data-dump-report"
            render={(props) => {
              return <DataDumpReport {...props} />;
            }}
          ></Route>

          <Route
            path="/finixfooter-links"
            render={(props) => {
              return <FinxFooterLinks {...props} />;
            }}
          ></Route>
          <Route
            path="/unassigned-users-report"
            render={(props) => {
              return <UnassignedUsersReportLanding {...props} />;
            }}
          ></Route>
          <Route
            path="/educationalLoanList"
            render={(props) => {
              return <EducationalLoanList {...props} />;
            }}
          ></Route>
          <Route
            exact
            path="/morningstarindia-jobs-research"
            render={(props) => {
              return <MorningStar {...props} />;
            }}
          ></Route>
          <Route
            exact
            path="/kotakbank-career-jobs"
            render={(props) => {
              return <Kotak {...props}/>;
            }}
          ></Route>
          <Route
            exact
            path="/BFSI-Hiring"
            render={(props) => {
              return <CorporateLandingPage {...props}/>;
            }}
          ></Route>
             <Route
              exact
              path="/higher-education-courses"
              render={(props) => {
                return <CorporateEdLandingPage {...props} />;
              }}
            ></Route>
          <Route
            path="/ops-dashboard"
            render={(props) => {
              return <Opsdashboardlanding {...props} />;
            }}
          ></Route>
          <Route
            path="/opsheader"
            render={(props) => {
              return <Opsheader {...props} />;
            }}
          ></Route>
          {/* <Route
            path="/candidate"
            render={(props) => {
              return <Candidate {...props} />;
            }}
          ></Route>
           <Route
            path="/admin-candidate"
            render={(props) => {
              return <AdminCandidate {...props} />;
            }}
          ></Route> */}
          <Route
            path="/candidate-details"
            render={(props) => {
              return <CandidateDetails {...props} />;
            }}
          ></Route>
          {/* <Route
            path="/job"
            render={(props) => {
              return <Job {...props} />;
            }}
          ></Route>
          <Route
            path="/createJob"
            render={(props) => {
              return <CreateJob {...props} />;
            }}
          ></Route>
          <Route
            path="/editJob"
            render={(props) => {
              return <EditJob {...props} />;
            }}
          ></Route> */}
          <Route
            path="/jobDetails"
            render={(props) => {
              return <JobDetails {...props} />;
            }}
          ></Route>
          <Route
            path="/uploadPayment"
            render={(props) => {
              return <UploadPayment {...props} />;
            }}
          ></Route>
          <Route
            path="/studentSection"
            render={(props) => {
              return <StudentSection {...props} />;
            }}
          ></Route>
          {/* <Route
            path="/bulkJob"
            render={(props) => {
              return <BulkJob {...props} />;
            }}
          ></Route>
          <Route
            path="/adminBulkJob"
            render={(props) => {
              return <AdminBulkJob {...props} />;
            }}
          ></Route>
          <Route
            path="/adminJob"
            render={(props) => {
              return <AdminJob {...props} />;
            }}
          ></Route>
          <Route
            path="/adminCreateJob"
            render={(props) => {
              return <AdminCreateJob {...props} />;
            }}
          ></Route>
          <Route
            path="/adminEditJob"
            render={(props) => {
              return <AdminEditJob {...props} />;
            }}
          ></Route> */}
          <Route
            path="/job-list"
            render={(props) => {
              return <JobListMaster {...props} />;
            }}
          ></Route>
          <Route
            path="/add-job"
            render={(props) => {
              return <AddJobMaster {...props} />;
            }}
          ></Route>
          <Route
            path="/edit-job"
            render={(props) => {
              return <EditJobMaster {...props} />;
            }}
          ></Route>
          {/* ops master routes start */}
          <Route
            path="/ops-course-list"
            render={(props) => {
              return <OpsProductList {...props} />;
            }}
          ></Route>

          <Route
            path="/ops-add-course"
            render={(props) => {
              return <OpsProductAddList {...props} />;
            }}
          ></Route>

          <Route
            path="/ops-edit-course"
            render={(props) => {
              return <OpsProductEdit {...props} />;
            }}
          ></Route>
          <Route
            path="/ops-add-course-student"
            render={(props) => {
              return <OpsAddProductUser {...props} />;
            }}
          ></Route>
          <Route
            path="/ops-discount-list"
            render={(props) => {
              return <OpsDiscountList {...props} />;
            }}
          ></Route>

          <Route
            path="/ops-add-discount"
            render={(props) => {
              return <OpsDiscountAdd {...props} />;
            }}
          ></Route>

          <Route
            path="/ops-edit-discount"
            render={(props) => {
              return <OpsDiscountEdit {...props} />;
            }}
          ></Route>

          <Route
            path="/ops-product-validity-list"
            render={(props) => {
              return <OpsProductValidityList {...props} />;
            }}
          ></Route>

          <Route
            path="/ops-add-product-validity"
            render={(props) => {
              return <OpsProductValidityAdd {...props} />;
            }}
          ></Route>

          <Route
            path="/ops-edit-product-validity"
            render={(props) => {
              return <OpsProductValidityEdit {...props} />;
            }}
          ></Route>
          <Route
            path="/ops-clone-discount"
            render={(props) => {
              return <OpsDiscountClone {...props} />;
            }}
          ></Route>
          <Route
            path="/ops-event-list"
            render={(props) => {
              return <OpsEventList {...props} />;
            }}
          ></Route>

          <Route
            path="/ops-add-event"
            render={(props) => {
              return <OpsEventAdd {...props} />;
            }}
          ></Route>

          <Route
            path="/ops-edit-event"
            render={(props) => {
              return <OpsEventEdit {...props} />;
            }}
          ></Route>

          <Route
            path="/ops-video-list"
            render={(props) => {
              return <OpsVideoList {...props} />;
            }}
          ></Route>

          <Route
            path="/ops-add-video"
            render={(props) => {
              return <OpsVideoAdd {...props} />;
            }}
          ></Route>

          <Route
            path="/ops-edit-video"
            render={(props) => {
              return <OpsVideoEdit {...props} />;
            }}
          ></Route>
          <Route
            path="/download-documents"
            render={(props) => {
              return <DownloadDocuments {...props} />;
            }}
          ></Route>

          {/* ops master routes end */}
        </div>
      </Router>
     </>
    );
  }
}
);
export default withRouter(routeFunction);
